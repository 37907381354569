<template>
  <v-container fluid class="pa-15">
    <v-container fluid class="pa-0 pb-5">
      <v-row
        justify="space-between"
        align="center"
        class="ma-0"
        style="height: 48px"
      >
        <!-- Contenedor de los botones -->
        <v-col
          cols="12"
          sm="6"
          md="auto"
          class="d-flex align-center order-3 order-md-2"
          style="gap: 16px"
        >
          <!-- Botón Agregar compra -->
          <v-btn
            style="
              text-align: center;
              color: #f0be43;
              font-size: 15px;
              background-color: transparent;
              font-family: montserrat-bold;
              font-weight: 600;
              line-height: 24px;
              text-transform: capitalize;
              word-wrap: break-word;
              border-radius: 5px;
              border: 1px solid #f0be43;
              padding: 8px 16px;
            "
            elevation="0"
            @click="openModalCreate"
          >
            <v-icon left>mdi mdi-file-document-plus-outline</v-icon>
            Agregar compra
          </v-btn>
          <!-- Botón Pagar -->
          <v-btn
            class="button-add mon-bold ml-2"
            elevation="0"
            style="width: 120px"
            @click="tab === 0 ? sendToPayNow() : sendToPayNowMxn()"
          >
            <v-icon left class="mr-2">mdi-currency-usd</v-icon>
            <div style="color: #000">Pagar</div>
          </v-btn>
        </v-col>
        <!-- Contenedor de las pestañas -->
        <v-col
          cols="12"
          sm="6"
          md="auto"
          class="d-flex align-start pa-0 order-2 order-md-1"
        >
          <v-tabs
            v-model="tab"
            background-color="transparent"
            fixed-tabs
            active-class="active"
          >
            <v-tabs-slider color="transparent"></v-tabs-slider>
            <!--Color de la pestaña seleccionada que sea transparente -->
            <!-- Pestaña USD -->
            <v-tab
              class="py-2 px-1"
              :class="{ 'tab-selected': tab === 0 }"
              active-class="background-color: transparent"
            >
              <v-row
                justify="center"
                align="center"
                class="d-flex flex-column align-center"
                style="gap: 2px"
              >
                <span class="text-center" :style="tabStyle(0)">USD</span>
                <div
                  v-if="tab === 0"
                  style="width: 39px; height: 6px; position: relative"
                >
                  <div
                    style="
                      width: 35px;
                      height: 3px;
                      left: 2px;
                      top: 3px;
                      position: absolute;
                    "
                  ></div>
                </div>
              </v-row>
            </v-tab>
            <!-- Pestaña MXN -->
            <v-tab class="py-2 px-4" :class="{ 'tab-selected': tab === 1 }">
              <v-row
                justify="center"
                align="center"
                class="d-flex flex-column align-center"
              >
                <span class="text-center" :style="tabStyle(1)">MXN</span>
              </v-row>
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="tab === 0" style="width: 100%">
      <v-simple-table
        style="width: 100%; border: 1px solid #e8e8e8"
        elevation="0"
      >
        <template class="full-width">
          <thead style="justify-content: center; background-color: #f2f2f4">
            <tr style="justify-content: center">
              <th class="text-center">
                <v-checkbox
                  v-model="bSelectedAll"
                  :input-value="false"
                  color="#f0be43"
                  hide-details
                  @change="toggleAll"
                ></v-checkbox>
              </th>
              <th class="text-header text-center">
                {{ texts.dataTable.provider }}
              </th>
              <th class="text-header text-center">
                {{ texts.dataTable.purchaseDate }}
              </th>
              <th class="text-header text-center">
                {{ texts.dataTable.invoice }}
              </th>
              <th class="text-header text-center">
                {{ texts.dataTable.totalAmount }} MXN
              </th>
              <th class="text-header text-center">
                {{ texts.dataTable.dueDate }}
              </th>
              <th class="text-header text-center">
                {{ texts.dataTable.exchangeRateProtection }} MXN
              </th>
              <!-- <th class="text-header text-center">
                {{ texts.dataTable.totalProtectedUsd }}
              </th>
              <th class="text-header text-center">
                {{ texts.dataTable.totalUsdToday }}
              </th> -->
              <th class="text-header text-center">
                {{ texts.dataTable.currencyProfit }}
              </th>
              <th class="text-header text-center">
                {{ texts.dataTable.currencyGain }} USD
              </th>
              <th class="text-header text-center">
                {{ texts.dataTable.status }}
              </th>
              <th class="text-header text-center">
                {{ texts.dataTable.actions }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="exchangeRateData in exchangeRateData"
              :key="exchangeRateData.id"
            >
              <td class="text-center">
                <v-checkbox
                  v-model="exchangeRateData.bSelected"
                  :disabled="
                    exchangeRateData.statusName === 'WAITING_FOR_DEPOSIT' ||
                    exchangeRateData.statusName === 'PROCESSING'
                  "
                  :input-value="false"
                  color="#f0be43"
                  hide-details
                ></v-checkbox>
              </td>
              <td class="text-row text-center">
                {{ exchangeRateData.sProviderName }}
              </td>
              <td class="text-row text-center">
                {{ formatDate(exchangeRateData.dPurchaseDate) }}
              </td>
              <td class="text-row text-center">
                {{ exchangeRateData.sInvoiceNumber }}
              </td>
              <td class="text-row text-center">
                ${{ formatMoney(exchangeRateData.dTotalAmount) }}
              </td>
              <td class="text-row text-center">
                {{ formatDate(exchangeRateData.dDueDate) }}
              </td>
              <td class="text-row text-center">
                ${{ exchangeRateData.dInvoiceExchangeRateProtection }}
              </td>
              <!-- <td class="text-row text-center">
                ${{ formatMoney(exchangeRateData.dTotalProtectedUsd) }}
              </td>
              <td class="text-row text-center">
                ${{ formatMoney(exchangeRateData.dTotalUsdToday) }}
              </td> -->
              <td
                class="text-row text-center"
                :style="{
                  color:
                    exchangeRateData.dCurrencyProfitPercentage < 0
                      ? 'red'
                      : 'green'
                }"
                style="font-weight: 800"
              >
                {{ exchangeRateData.dCurrencyProfitPercentage }}
              </td>
              <td
                class="text-row text-center"
                :style="{
                  color: exchangeRateData.dCurrencyGain < 0 ? 'red' : 'green'
                }"
                style="font-weight: 800"
              >
                ${{ formatMoney(exchangeRateData.dCurrencyGain) }}
              </td>

              <td class="text-header text-center">
                <!-- Make a dynamic attribute of object -->
                {{ texts.status[exchangeRateData.statusName] }}
              </td>

              <td class="text-row text-center">
                <div class="display-flex align-items-center justify-center">
                  <div class="ml-4">
                    <v-btn
                      color="#f5f7f9"
                      elevation="0"
                      :disabled="
                        exchangeRateData.statusName === 'WAITING_FOR_DEPOSIT' ||
                        exchangeRateData.statusName === 'PROCESSING'
                      "
                      @click="openModal(exchangeRateData)"
                      style="width: 24px"
                    >
                      <v-icon color="#6e808d">mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>

    <v-container fluid v-if="tab === 1" style="width: 100%">
      <v-simple-table
        style="width: 100%; border: 1px solid #e8e8e8"
        elevation="0"
      >
        <template class="full-width">
          <thead style="justify-content: center; background-color: #f2f2f4">
            <tr style="justify-content: center">
              <th class="text-center">
                <v-checkbox
                  v-model="bSelectedAll"
                  :input-value="false"
                  color="#f0be43"
                  hide-details
                  @change="toggleAllSeparated"
                ></v-checkbox>
              </th>
              <th class="text-center">
                {{ texts.dataTable.provider }}
              </th>
              <th class="text-center">
                {{ texts.dataTable.purchaseDate }}
              </th>
              <th class="text-center">
                {{ texts.dataTable.invoice }}
              </th>
              <th class="text-center">{{ texts.dataTable.totalAmount }} MXN</th>
              <th class="text-center">
                {{ texts.dataTable.dueDate }}
              </th>
              <th class="text-center">
                {{ texts.dataTable.exchangeRateProtection }} MXN
              </th>
              <th class="text-center">Tipo de cambio</th>
              <!-- <th class="text-center">
                {{ texts.dataTable.totalProtectedUsd }} USD
              </th>
              <th class="text-center">
                {{ texts.dataTable.totalUsdToday }} USD
              </th> -->
              <th class="text-center">
                {{ texts.dataTable.currencyProfit }}
              </th>
              <th class="text-center">
                {{ texts.dataTable.currencyGain }} USD
              </th>
              <th class="text-center">{{ texts.dataTable.status }}</th>
              <th class="text-center">
                {{ texts.dataTable.actions }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="exchangeRateData in exchangeRateDataSeparate"
              :key="exchangeRateData.id"
            >
              <td class="text-center">
                <v-checkbox
                  v-model="exchangeRateData.bSelected"
                  :input-value="false"
                  color="#f0be43"
                  hide-details
                ></v-checkbox>
              </td>

              <td class="text-center">
                {{ exchangeRateData.sProviderName }}
              </td>
              <td class="text-center">
                {{ formatDate(exchangeRateData.dPurchaseDate) }}
              </td>
              <td class="text-center">
                {{ exchangeRateData.sInvoiceNumber }}
              </td>
              <td class="text-center">
                ${{ formatMoney(exchangeRateData.dTotalAmount) }}
              </td>
              <td class="text-center">
                {{ formatDate(exchangeRateData.dDueDate) }}
              </td>
              <td class="text-center">
                ${{ exchangeRateData.dInvoiceExchangeRateProtection }}
              </td>

              <td class="text-center">
                {{ formatMoney(exchangeRateData.sExchangeRateUsed) }}
              </td>
              <!-- <td class="text-center">
                ${{ formatMoney(exchangeRateData.dTotalProtectedUsd) }}
              </td>
              <td class="text-center">
                ${{ formatMoney(exchangeRateData.dTotalUsdToday) }}
              </td> -->
              <td
                class="text-center"
                :style="{
                  color:
                    exchangeRateData.dCurrencyProfitPercentage < 0
                      ? 'red'
                      : 'green'
                }"
                style="font-weight: 800"
              >
                {{ exchangeRateData.dCurrencyProfitPercentage }} %
              </td>
              <td
                class="text-center"
                :style="{
                  color: exchangeRateData.dCurrencyGain < 0 ? 'red' : 'green'
                }"
                style="font-weight: 800"
              >
                ${{ formatMoney(exchangeRateData.dCurrencyGain) }}
              </td>
              <td class="text-header text-center">
                <!-- Make a dynamic attribute of object -->
                {{ texts.status[exchangeRateData.statusName] }}
              </td>

              <td class="text-row text-center">
                <div class="display-flex align-items-center justify-center">
                  <div class="ml-4">
                    <v-btn
                      color="#f5f7f9"
                      elevation="0"
                      :disabled="
                        exchangeRateData.statusName === 'WAITING_FOR_DEPOSIT' ||
                        exchangeRateData.statusName === 'PROCESSING'
                      "
                      @click="openModal(exchangeRateData)"
                      style="width: 24px"
                    >
                      <v-icon color="#6e808d">mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </v-container>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      required: true
    },
    exchangeRateData: {
      type: Array,
      required: true
    },
    toggleAll: {
      type: Function,
      required: true
    },
    bSelectedAll: {
      type: Boolean,
      required: true
    },
    changebSelectedAll: {
      type: Function,
      required: true
    },
    actualCurrency: {
      type: Number | String,
      default: "0"
    },
    formatDate: {
      type: Function,
      required: true
    },
    balanceMxn: {
      type: String,
      required: true
    },
    balanceUsd: {
      type: String,
      required: true
    },
    formatMoney: {
      type: Function,
      required: true
    },
    openModalCreate: {
      type: Function,
      required: true
    },
    closeModalCreate: {
      type: Function,
      required: true
    },
    sendToPayNow: {
      type: Function,
      required: true
    },
    exchangeRateDataSeparate: {
      type: Array,
      required: true
    },
    toggleAllSeparated: {
      type: Function,
      required: true
    },
    bSelectedAllSeparate: {
      type: Boolean,
      required: true
    },
    sendToPayNowMxn: {
      type: Function,
      required: true
    },
    openModal: {
      type: Function,
      required: true
    },
    modalCurrency: {
      type: Number,
      required: true
    },
    changeModalCurrency: {
      type: Function,
      required: true
    }
  },
  name: "CustomTabs",

  data() {
    return {
      gradientStyle: {
        background: "linear-gradient(90deg, #f0be43 0%, #f0be43 100%)"
      },
      tab: 0
    };
  },
  //methods
  methods: {
    tabStyle(tabIndex) {
      return {
        color: this.tab === tabIndex ? "#f0be43" : "#506172",
        fontSize: "16px",
        fontWeight: "600",
        width: "200px",
        lineHeight: "24px",
        fontFamily: "montserrat-bold"
      };
    }
  },
  watch: {
    tab(val) {
      this.changeModalCurrency(val);
    }
  }
};
</script>

<style scoped>
.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.v-tabs-slider {
  background: #f0be43 !important;
  height: 3px;
  color: #f0be43 !important;
  border-radius: 100px;
}

.text-caption {
  font-size: 12px !important;
  line-height: 16px !important;
}

.font-weight-medium {
  font-weight: 600 !important;
}

.cell-status {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.status-content {
  background-color: #ececec;
  padding: 10px;
  border-radius: 3px;
  width: auto;
}

.text-header {
  color: #333131;
  font-size: 12px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
  text-align: center;
}

.text-row {
  flex: 1 1 0;
  color: #302e2e;
  font-size: 14px;
  font-family: montserrat-bold;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.text-center {
  text-align: center;
  justify-content: center;
  font-family: montserrat-regular;
}
.active {
  color: #f0be43 !important;
}
</style>
